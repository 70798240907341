<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <span>Only latest <strong>20</strong> records will be shown</span>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="query"
              >
                <span class="text-nowrap">Refresh</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        class="position-relative"
        :items="downloadList"
        striped
        responsive
        :fields="tableFields"
        show-empty
        empty-text="No matching records found"
      >

        <template #cell(show_details)="row">
          <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
          <b-form-checkbox
            v-model="row.detailsShowing"
            @change="row.toggleDetails"
          >
            {{ row.detailsShowing ? 'Hide' : 'Show' }}
          </b-form-checkbox>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <span v-if="data.item.status === 2">
            <a
              target="_blank"
              :href="data.item.download_url"
            >
              <b-button variant="flat-primary">
                Download
              </b-button>
            </a>
          </span>
          <span v-if="data.item.status !== 2">
            {{ formatStatus(data.item.status) }}
          </span>
        </template>

        <!-- full detail on click -->
        <template #row-details="row">
          <b-card>
            <b-row class="mb-2">
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>Merchant NO. : </strong>{{ JSON.parse(row.item.conditions).merchantNo }}
              </b-col>
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>APP ID : </strong>{{ JSON.parse(row.item.conditions).appId }}
              </b-col>
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>Method : </strong>{{ JSON.parse(row.item.conditions).method }}
              </b-col>
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>Channel : </strong>{{ JSON.parse(row.item.conditions).channel }}
              </b-col>
            </b-row>

            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
            >
              Hide Details
            </b-button>
          </b-card>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BFormCheckbox,
} from 'bootstrap-vue'
import useTransacitonsJs from './transactions'

const {
  fetchDownloadList,
} = useTransacitonsJs()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BFormCheckbox,
  },
  data() {
    return {
      downloadList: [],

      tableFields: [
        'show_details',
        { key: 'create_time', label: 'CREATE TIME' },
        { key: 'start_time', label: 'START' },
        { key: 'end_time', label: 'END' },
        { key: 'result', label: 'RESULT' },
        { key: 'update_time', label: 'UPDATE TIME' },
        { key: 'status', label: 'STATUS' },
      ],
    }
  },
  created() {
    this.query()
  },
  methods: {
    formatStatus(status) {
      switch (status) {
        case 0:
          return 'Processing'
        case 1:
          return 'Error'
        case 2:
          return 'Download'
        default:
          return 'N/A'
      }
    },
    query() {
      fetchDownloadList(this, list => {
        this.downloadList = list
      })
    },
  },
}
</script>
